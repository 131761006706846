@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.container{
  position: relative;
  max-width: 500px;
  width: 100%;
  padding: 0%;
}

.container .skill-box {
  width: 100%;
  margin: 20px 0;
}

.skill-box .title{
  display: block;
  font-size: 14px;
  font-weight: 600;
  color: #888888;
}

.skill-box .skill-bar{
  height: 8px;
  width: 100%;
  border-radius: 6px;
  margin-top: 6px;
  background: #333333;
}

.skill-box .skill-per{
  position: relative;
  display: block;
  height: 100%;
  width: 95%;
  border-radius: 6px;
  background: #D3D3D3;
  animation: progress 0.4s ease-in-out forwards;
  opacity: 0;
}

.skill-per.reactjs {
  width: 90%;
  animation-delay: 0.1s;
}

.skill-per.swift {
  width: 65%;
  animation-delay: 0.1s;
}

.skill-per.nodeExpress {
  width: 80%;
  animation-delay: 0.1s;
}

.skill-per.htmlcss{
  width: 90%;
  animation-delay: 0.2s;
}
.skill-per.java{
  width: 80%;
  animation-delay: 0.3s;
}
.skill-per.redux{
  width: 70%;
  animation-delay: 0.3s;
}
.skill-per.mongoDB {
  width: 70%;
  animation-delay: 0.1s;
}
.skill-per.mysql{
  width: 70%;
  animation-delay: 0.3s;
}
.skill-per.git{
  width: 85%;
  animation-delay: 0.3s;
}
.skill-per.androidStudio{
  width: 60%;
  animation-delay: 0.3s;
}
.skill-per.r{
  width: 60%;
  animation-delay: 0.3s;
}
.skill-per.autodesk{
  width: 85%;
  animation-delay: 0.3s;
}
.skill-per.adobe{
  width: 80%;
  animation-delay: 0.3s;
}

@keyframes progress {
  0% {
      width: 0;
      opacity: 1;
  }

  100% {
      opacity: 1;
  }
}

.skill-per .tooltip{
  position: absolute;
  right: -14px;
  top: -28px;
  font-size: 10px;
  font-weight: 500;
  color: #fff;
  padding: 2px 6px;
  border-radius: 3px;
  background: #3a3b3c;
  z-index: 1;
  opacity: 1;
}

.tooltip::before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -2px;
  height: 10px;
  width: 10px;
  z-index: -1;
  background-color: #3a3b3c;
  transform: translateX(-50%) rotate(45deg);
}