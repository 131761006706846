#contact {
  background-image: url("../../images/diagstrokes.jpg");
  background-size: cover;
  background-repeat: no-repeat; 
  color: white;
}

.contactHeader {
  text-align: center;
  color: #d4af37;
  margin: auto;
  padding-top: 150px;
}

.contactSubtext {
  text-align: center;
  margin: 10px;
  font-size: 25px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.connections {
  width: 70%;
  margin: auto;
  margin-top: 80px;
  display: flex;
  justify-content: center;
  padding-bottom: 150px;
}

.connectionDiv {
  width: 100%;
  margin-top: 25px;
  margin-bottom: 150px;
}

.connectionIcon {
  margin: auto;
  display: flex;
}

.conHeader {
  color: #d4af37;
  text-shadow: 1px 2px 1px black;
  text-align: center;
  margin-top: 30px;
}

.conText {
  text-align: center;
  font-family: "poppins-regular", sans-serif;
}

.conAnchor {
  text-decoration: none;
  margin: auto;
  display: inline-block;
  color: #37D4AF;
  transition: 200ms;
}

.conAnchor:hover {
  color: #4f4f4f;
}

@media only screen and ( max-width: 750px ) {
  .contactHeader {
    padding-top: 120px;
  }

  .contactSubtext {
    margin-left: 20px;
    margin-right: 20px;
  }

  .connections {
    display: inline-block;
    margin-top: 40px;
    width: 100%;
  }

  .connectionIcon {
    width: 100%;
    margin: auto;
  }

  .connectionDiv {
    width: 100%;
    margin-bottom: 40px;
  }
}