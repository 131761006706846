 #modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  width: 500px;
  padding-bottom: 0;
  margin-bottom: 0;
  z-index: 1000;
  animation:fadein .5s;
} 

@keyframes fadein {
	from {
		opacity:0;
	}
	to {
		opacity:1;
	}
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .7);
  z-index: 1000;
}

.modal-image {
  width: 100%;
  height: 100%;
}

.modal-info {
  width: 100%;
  padding: 12px 36px 18px 36px;
  margin-top: 15px;
}

.modal-item-name {
  font-family: 'opensans-bold', sans-serif;
  font-size: 20px;
}

.modal-item-description {
  color: #A1A1A1;
  font-family: 'opensans-regular', sans-serif;
  font-size: 15px;
}

.modal-item-type {
  color: #A1A1A1;
  font-size: 12px;
  text-transform: uppercase;
  font-family: 'opensans-light', sans-serif;
  letter-spacing: 2px;
}

.link-box {
  background-color: #0f0f0f;
  padding: 18px 36px 18px 36px;
  margin-bottom: 0;
}

.modal-details {
  color: white;
  font-size: 15px;
  letter-spacing: 1.5px;
  margin-right: 30px;
  display: inline-block;
  text-decoration: none;
}

.modal-details:hover {
  cursor: pointer;
  color: white;
}

.modal-close {
  color: white;
  display: inline-block;
  font-size: 15px;
  letter-spacing: 1.5px;
}

.modal-close:hover {
  cursor: pointer;
}

@media only screen and (max-width: 500px) {
  #modal {
    width: 90%;
  }
}