#header {
  text-align: center;
  justify-content: center;
  color: #fff;
  position: relative;
  height: 100vh;
  width: 100%;
  background-image: url('../../images/chicago.jpg');
  background-attachment: local;
  background-size: cover;
  background-repeat: no-repeat; 
  display: flex;
  flex-direction: column;
  letter-spacing: -2px;
  text-shadow: 0px 2px 10px rgb(0 0 0 / 80%);
  font-size: 5vw;
}

.header-content {
  margin: auto;
  margin-top: 335px;
  padding: 10px;
}

.title {
  font-size: max(40px, 5vw);
}

.title-alias {
  font-family: 'Times New Roman', Times, serif;
  letter-spacing: 1px;
  text-shadow: 0px 2px 10px rgb(0 0 0 / 80%);
}

.iconCollection {
  width: 200px;
  margin: auto;
  color: #37D4AF;
  display: flex; 
  justify-content: space-between;
}

.headerIcon {
  color: white;
  transition: 500ms;
}

.iconAnchor {
  padding: 0;
  margin: 0;
  display:inherit
}

.headerIcon:hover {
  color: #37D4AF;
}

@media only screen and ( max-width: 750px ) {
  #header {
    background-size: auto 100%;
  }
}