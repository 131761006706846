.header {
  position: fixed;
  height: 90px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  transition: .3s ease-in;
  overflow: hidden;
  background-color: transparent;
}

.header.active {
  background-color: rgba(0,0,0,.9);
}

.header .navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: 100%;
  padding: 0 1rem;
}

.header .nav-menu a {
  color: #ffffff;
}

.header .nav-menu {
  display: flex;
}

.header .nav-item {
  padding: 1rem;
  font-weight: 500;
}

.header .nav-item a:hover {
  padding-bottom: 4px;
  /* color: #d4af37; */
  color: #37D4AF;
  cursor: pointer;
}

.nav-menu a.active {
  color: #d4af37;
  text-decoration: none;
}

.hamburger {
  display: none;
}

.nav-item {
  list-style: none;
}

#homeLink {
  text-decoration: none;
}

@media screen and (max-width:940px) {
  .header {
      max-width: 100%;
      background-color: rgba(0,0,0,.9);
  }

  .header .navbar {
      max-width: 100%;
      justify-content: space-between;
  }

  .hamburger {
      display: block;
  }

  .nav-menu {
      position: fixed;
      left: -100%;
      top: 90px;
      flex-direction: column;
      background-color: rgba(0,0,0,.9);
      width: 100%;
      height: 90vh;
      z-index: 999;
      text-align: center;
      transition: .3s;
  }

  .nav-menu.active {
      left: 0;
  }

  .nav-item {
      margin: 1.5rem 0;
  }

  .header .navbar img {
      width: 150px;
  }
}