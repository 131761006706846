#resume {
  margin-top: 50px;
}

.resumeTitle {
  text-align: center;
  color: #d4af37;
}

.resumeSubTitle {
  text-align: center;
}