.timeline{
  /* font-family: cursive;   ----temp */
  margin: auto;
  max-width:800px;
  color:#000000;
  padding:30px 20px;
}
.timeline ul{
  list-style-type:none;
  border-left:2px solid black;
  padding:10px 5px;
}
.timeline ul li{
  padding:20px 20px;
  position:relative;
  cursor:pointer;
  transition:.5s;
}
.timeline ul li span{
  display:inline-block;
  color: white;
  background-color:#2b2d2f;
  
  border-radius:25px;
  padding:2px 5px;
  font-size:15px;
  text-align:center;
}
.timeline ul li .content h3{
  color:#d4af37;
  font-size:17px;
  padding-top:5px;
}
.timeline ul li .content p{
  padding:5px 0px 15px 0px;
  font-size:15px;
}
.timeline ul li:before{
  position:absolute;
  content:'';
  width:10px;
  height:10px;
  background-color:#000000;
  border-radius:50%;
  left:-11px;
  top:28px;
  transition:.5s;
}
.timeline ul li:hover{
  background-color:#FFE87C;
}
.timeline ul li:hover:before{
  background-color:#d4af37;
  box-shadow:0px 0px 10px 2px #d4af37;
}
.locationName {
  color: #333333;
  padding-top: 5px;
}
.listTitle {
  color: #333333;
  text-align: center;
}

@media (max-width:300px){
  .timeline{
    width:100%;
    padding:30px 5px 30px 10px;
  }
  .timeline ul li .content h3{
    color:#34ace0;
    font-size:15px;
  }

}