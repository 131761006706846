.projectContainer {
  margin: auto;
  margin-right: 10px; 
  padding-bottom: 10px;
}

.project {
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 20px;
  float: left;
  -webkit-transition-property: all; 
  -webkit-transition-duration: 0.3s; 
  -webkit-transition-timing-function: ease; 
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 20rem;
  height: auto;
}

.project:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.projectImage {
  width: 100%;
  height: 100%;
}

body.active-modal {
  overflow-y: hidden;
}

.btn-modal {
  padding: 10px 20px;
  display: block;
  margin: 100px auto 0;
  font-size: 18px;
}

.modal, .overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.overlay {
  background: rgba(49,49,49,0.8);
}
.modal-content {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  padding: 14px 28px;
  border-radius: 3px;
  max-width: 600px;
  min-width: 300px;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 7px;
}