#portfolio {
  padding-top: 100px;
  padding-bottom: 10px;
  background-color: #f6f6f6;
  min-height: 100px;
  overflow: hidden;
}

.portfolioTitle {
  color: #d4af37;
  text-align: center;
}

.portfolioSubTitle {
  text-align: center;
  margin-bottom: 20px;
}

.animation-top-layer {
  display: flex;
  align-items: center;
  min-height: 30vh;
  justify-content: center;
}

.projectContainerOuterlayer {
  width: 400%;
  height: 400px;
  border-top: 2px;
  border-bottom: 2px;
  border-color: gray;
  overflow: hidden;
  position: relative;
}

.projectContainerInnerlayer {
  width: 400%;
  display: flex;
  align-items: center;
  height: auto;
  justify-content: space-around;
  position: absolute;
  left: 0;
  animation: scroll 15s linear infinite;
  gap: 0;
}

@keyframes scroll {
  0% {
    left: 0;
  }
  100% {
    left: -200%;
  }
}

.animate {
  animation: scroll 30s linear infinite;
}

@media only screen and ( max-width: 1200px ) {
  .projectContainerOuterlayer {
    height: 300px;
  }
}

@media only screen and ( max-width: 800px ) {
  .projectContainerOuterlayer {
    height: 200px;
  }
}

@media only screen and ( max-width: 550px ) {
  .projectContainerOuterlayer {
    height: 400px;
  }

  .projectContainerOuterlayer {
    width: 800%;
    height: 300px;
  }
  
  .projectContainerInnerlayer {
    width: 800%;
  }

  @keyframes scroll {
    0% {
      left: 0;
    }
    100% {
      left: -400%;
    }
  }
}