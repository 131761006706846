#aboutMe {
  background-color: #111111;
  color: #d4af37;
}

.aboutMeContent {
  max-width: 900px;
  margin: auto;
}

.aboutMeHeader {
  text-align: center;
  padding-top: 100px;
}

.introSection {
  display: flex;
  margin: auto;
  max-width: 700px;
  color: #808080;
  margin-bottom: 30px;
}

#selfPicture {
  width: 100px;
  height: 100px;
}

.introText {
  padding-left: 10px;
  margin-left: 10px;
}

.profileSection {
  color: #808080;
}

.segmentTitle {
  color: white;
}

.aboutText {
  margin-bottom: 50px;
}

.personalDetail {
  margin-top: 20px;
  color: #888888;
}

.detail {
  color: #cccccc;
}

.anchor {
  color: #37D4AF;
}

.anchor:hover {
  color: #37D4AF;
}

.skills {
  color: #808080;
}

.detailLabel {
  color: #5f5f5f;
}

.aboutMeButtons {
  margin-top: 50px;
  padding-bottom: 100px;
  text-align: center;
  box-shadow: none;
}

.aboutMeButtons .button {
  width: 250px;
}

.btn-1 {
  margin-right: 30px;
}

.button {
  background-color: #37D4AF;
  border-color: #37D4AF;
  border-radius: 5px;
  box-shadow: none;
  outline: none;
  display: inline-block;
  padding: 10px;
  color: #fff;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 20px;
  height: 70px;
  text-decoration: none;
  -webkit-transition-property: all; 
  -webkit-transition-duration: 0.2s; 
  -webkit-transition-timing-function: ease;
}

.resumeButton {
  padding: 23px;
  cursor: pointer;
}

.button:active {
  transform: none;
  box-shadow: none;
}

.button:hover {
  background-color: #808080;
  border-color: #808080;
  transition: 500ms;
  outline: none;
  color: white;
}

.col {
  margin-left: 20px;
  margin-right: 20px;
}

html, body {
  scroll-behavior: smooth;
  scroll-snap-type: y; 
}

@media (max-width: 2000px) {
  html, body {
  overflow-x: hidden;
  }
}

@media only screen and ( max-width: 750px ) {
  .introSection {
    display: block;
    margin: auto;
    max-width: 90%;
    color: #808080;
    margin-bottom: 30px;
    text-align: center;
    margin-bottom: 100px;
  }

  .aboutText {
    text-align: center;
  }

  .skillsText {
    width: auto;
  }
  
  #selfPicture {
    width: 100px;
    height: 100px;
    margin: auto;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    align-items: center;
  }
  
  .introText {
    padding-left: 10px;
    margin-left: 10px;
  }

  .segmentTitle {
    text-align: center;
  }

  .col {
    flex: auto;
  }

  /* add something here for making profile and skills on top of each other rather than side by side */
}

@media only screen and ( max-width: 550px ) {

  .aboutMeButtons {
    margin-top: 50px;
    padding-bottom: 100px;
    text-align: center;
    box-shadow: none;
    align-items: center;
  }

  .button {
    margin: auto;
    text-align: center;
  }

  .aboutMeButtons .button {
    width: 90%;
    text-align: center;
    margin-top: 15px;
  }

  .btn-1 {
    margin: auto;
  }

}